import axios from "axios";
import { Notify } from "vant";

// 全局配置
axios.defaults.headers = {
  // 'Content-Type': 'application/json;charset=UTF-8',
  error_message: true, // 错误消息提示
};
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
// 实例配置
console.log(process.env.VUE_APP_BASE_API);
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 10,
  // headers: { Authorization: localStorage.getItem('token') || '' }
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    const token = localStorage.getItem("token");
    if (!config.headers.Authorization && token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    const { data } = response;

    if (data.code !== "00000") {
      if (response.config.headers.error_message) {
        // Message.closeAll()
        Notify({ type: "danger", message: data.msg || "系统错误" }); // 提示错误消息
      }
    }
    return data;
  },
  (error) => {
    // 对响应错误做点什么
    if (error.response) {
      const { data } = error.response;
      Notify({ type: "danger", message: data.msg || "系统错误" }); // 提示错误消息
    }
    return error.response.data;
    // return Promise.reject(error)
  }
);

export default instance;
