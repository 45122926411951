import axios from '../request'
export default {
  getCourseDetail(id) {
    return axios({
      name: '获取教程详情',
      method: 'get',
      url: `/serve-user/app-api/v1/course/${id}`
    })
  }
}
