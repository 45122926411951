import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import api from './api'
import utils from './utils'
Vue.use(Vant);

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$utils = utils
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
