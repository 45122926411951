<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
}
html {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
}
html,
body,
div,
h1,
h2,
h3,
h4,
h5,
ul,
li,
p,
span {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
li {
  list-style-type: none;
}
input {
  box-sizing: border-box;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}
button {
  border: none;
  outline: none;
}
</style>
