// 获取设备类型
function getDeviceType() {
    const UA = navigator.userAgent;

    const type = {
        isAndroid: UA.indexOf("Android") > -1 || UA.indexOf("Adr") > -1, // Android
        isiOS: !!UA.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // iOS
        isiPhone: UA.indexOf("iPhone") > -1, // iPhone
        isiPad: UA.indexOf("iPad") > -1, // iPad
        isWeiXin: UA.indexOf("MicroMessenger") > -1, // 微信
    };

    return type;
}

export default {
    getDeviceType,
};
