import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/download/download',
    name: 'download',
    meta: {
      title: '下载'
    },
    component: () => import('@/views/download/Download.vue')
  },

  {
    path: '/anti-counterfeiting/query',
    name: 'query',
    meta: {
      title: '防伪查询'
    },
    component: () => import('@/views/anti-counterfeiting/query.vue')
  },
  {
    path: '/anti-counterfeiting/SearchResult',
    name: 'SearchResult',
    meta: {
      title: '防伪查询结果'
    },
    component: () => import('@/views/anti-counterfeiting/SearchResult .vue')
  },

  {
    path: '/documents/setNet',
    name: 'documentsSetNet',
    meta: {
      title: '配网说明'
    },
    component: () => import('@/views/documents/SetNet.vue')
  },
  {
    path: '/documents/wifiSwitch',
    name: 'documentsWifiSwitch',
    meta: {
      title: 'wifi开关匹配说明'
    },
    component: () => import('@/views/documents/WifiSwitch.vue')
  },
  {
    path: '/documents/wifiSocket',
    name: 'documentsWifiSocket',
    meta: {
      title: 'wifi插座匹配说明'
    },
    component: () => import('@/views/documents/WifiSocket.vue')
  },
  {
    path: '/documents/ZigBeeSwitch',
    name: 'documentsZigBeeSwitch',
    meta: {
      title: 'ZigBee开关匹配说明'
    },
    component: () => import('@/views/documents/ZigBeeSwitch.vue')
  },
  {
    path: '/documents/ZigBeeSocket',
    name: 'documentsZigBeeSocket',
    meta: {
      title: 'ZigBee插座匹配说明'
    },
    component: () => import('@/views/documents/ZigBeeSocket.vue')
  },
  {
    path: '/documents/ZigBeePaster',
    name: 'documentsZigBeePaster',
    meta: {
      title: 'ZigBee插座匹配说明'
    },
    component: () => import('@/views/documents/ZigBeePaster.vue')
  },
  {
    path: '/documents/zhiNengBanlLvT1s',
    name: 'documentsZhiNengBanlLvT1s',
    meta: {
      title: '智能伴侣匹配说明'
    },
    component: () => import('@/views/documents/ZhiNengBanlLvT1s.vue')
  },
  {
    path: '/documents/doorSensor',
    name: 'documentsDoorSensor',
    meta: {
      title: '门窗传感器匹配说明'
    },
    component: () => import('@/views/documents/DoorSensor.vue')
  },
  {
    path: '/documents/waterSensor',
    name: 'documentsWaterSensor',
    meta: {
      title: '水浸传感器匹配说明'
    },
    component: () => import('@/views/documents/WaterSensor.vue')
  },
  {
    path: '/documents/humanSensor',
    name: 'documentsHumanSensor',
    meta: {
      title: '人体传感器匹配说明'
    },
    component: () => import('@/views/documents/HumanSensor.vue')
  },
  {
    path: '/documents/smartButton',
    name: 'documentsSmartButton',
    meta: {
      title: '智能按钮匹配说明'
    },
    component: () => import('@/views/documents/SmartButton.vue')
  },
  {
    path: '/documents/wirelessInfraredGateway',
    name: 'documentsWirelessInfraredGateway',
    meta: {
      title: '无线红外网关匹配说明'
    },
    component: () => import('@/views/documents/WirelessInfraredGateway.vue')
  },
  {
    path: '/documents/ZigBeeWirelessGateway',
    name: 'documentsZigBeeWirelessGateway',
    meta: {
      title: 'ZigBee无线网关匹配说明'
    },
    component: () => import('@/views/documents/ZigBeeWirelessGateway.vue')
  },
  {
    path: '/documents/ZigBeeMultiControlSwitch',
    name: 'documentsZigBeeMultiControlSwitch',
    meta: {
      title: 'ZigBee多控开关匹配说明'
    },
    component: () => import('@/views/documents/ZigBeeMultiControlSwitch.vue')
  },
  {
    path: '/documents/smartCurtain',
    name: 'documentsSmartCurtain',
    meta: {
      title: '智能窗帘匹配说明'
    },
    component: () => import('@/views/documents/SmartCurtain.vue')
  },
  {
    path: '/documents/commonProblem',
    name: 'documentsCommonProblem',
    meta: {
      title: '常见问题'
    },
    component: () => import('@/views/documents/CommonProblem.vue')
  },
  {
    path: '/documents/NetgearRouter',
    name: 'documentsNetgearRouter',
    meta: {
      title: '网件路由器设置'
    },
    component: () => import('@/views/documents/NetgearRouter.vue')
  },
  {
    path: '/documents/AsusRouter',
    name: 'documentsAsusRouter',
    meta: {
      title: '华硕路由器设置'
    },
    component: () => import('@/views/documents/AsusRouter.vue')
  },
  {
    path: '/documents/DLinkRouter',
    name: 'documentsDLinkRouter',
    meta: {
      title: 'D-Link路由器设置'
    },
    component: () => import('@/views/documents/DLinkRouter.vue')
  },
  {
    path: '/documents/TPLinkRouter',
    name: 'documentsTPLinkRouter',
    meta: {
      title: 'TP-Link路由器设置'
    },
    component: () => import('@/views/documents/TPLinkRouter.vue')
  },

  {
    path: '/life-service/phone-recharge',
    name: 'lifeServicePhoneRecharge',
    meta: {
      title: '话费充值'
    },
    component: () => import('@/views/life-service/phone-recharge.vue')
  },
  {
    path: '/life-service/living-payment',
    name: 'lifeServiceLivingPayment',
    meta: {
      title: '生活缴费'
    },
    component: () => import('@/views/life-service/living-payment.vue')
  },
  {
    path: '/life-service/order',
    name: 'lifeServiceOrder',
    meta: {
      title: '交易记录'
    },
    component: () => import('@/views/life-service/order.vue')
  },

  {
    path: '/course/render',
    name: 'courseRender',
    meta: {
      title: '教程'
    },
    component: () => import('@/views/course/Render.vue')
  },

  {
    path: '/share/video',
    name: 'shareVideo',
    meta: {
      title: '视频分享'
    },
    component: () => import('@/views/share/Video.vue')
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  routes: constantRoutes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
